import Vue from 'vue'
import store from '@/store'
import settings from '@/settings'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'
// import { anonymousRegister } from '@/api/user'
import { getToken } from '@/utils/auth' // get token from cookie
// import { Loading } from 'element-ui'
import { openLoginRegDialog } from '@/components/dialog/openLoginRegHandler'
const whiteList = ['/login', '/auth-redirect', '/register', 'VRView']
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '作品集市',
    },
  },
  {
    path: '/diy/:id',
    name: 'diy',
    component: () => import('../views/diy.vue'),
    meta: {
      title: '装修中心',
    },
  },
  {
    path: '/three/demo',
    name: 'threeDemo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/threeDemo.vue'),
    meta: {
      title: 'threeDemo',
    },
  },
  {
    path: '/three',
    name: 'threeVR',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/threeVR.vue'),
    meta: {
      title: 'threeVR',
    },
  },
  {
    path: '/three/v2',
    name: 'threeVR2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/threeVR2.vue'),
  },
  {
    path: '/photoSphereViewer',
    name: 'photoSphereViewer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/photoSphereViewer.vue'),
    meta: {
      title: 'photoSphereViewer',
    },
  },
  {
    path: '/photoSphereViewer/playground',
    name: 'photoSphereViewerPlayground',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/playground.vue'),
    meta: {
      title: 'photoSphereViewerPlayground',
    },
  },
  {
    path: '/view/:id',
    name: 'VRView',
    component: () => import('../views/view.vue'),
    meta: {
      title: '作品详情',
    },
  },
  {
    path: '/403',
    component: () => import('@/views/error-page/403'),
    meta: {
      title: '403 Forbidden',
    },
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    meta: {
      title: '404 Not Found',
    },
  },
]
const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: routes,
  })
const router = createRouter()

function getPageTitle(pageTitle, defaultTitle = null) {
  let title = settings.title
  if (defaultTitle) {
    title = defaultTitle
  }
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

// events
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  document.title = getPageTitle(to.meta.title)
  const token = getToken()
  if (token) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      next()
      NProgress.done()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1 || whiteList.indexOf(to.name) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      // next(`/login?redirect=${to.path}`)
      if (store.getters.allowAnonymousVisit) {
        // 匿名注册用户并自动登录
        openLoginRegDialog(store.getters.systemTitle, 'login', {
          onSubmit() {
            console.log('点了确定按钮')
          },
          onCancel() {
            console.log('点了取消按钮')
          },
        })
        // anonymousRegister()
        //   .then(async (response) => {
        //     const { code, message, data } = response
        //     if (code !== 0) {
        //       // eslint-disable-next-line no-unused-vars
        //       const loadingInstance = Loading.service({
        //         fullscreen: true,
        //         text: message,
        //       })
        //       return false
        //     }

        //     await store.dispatch('user/login', data)
        //     next()
        //   })
        //   // eslint-disable-next-line no-unused-vars
        //   .catch((_error) => {
        //     // eslint-disable-next-line no-unused-vars
        //     const loadingInstance = Loading.service({
        //       fullscreen: true,
        //       text: '服务器内部错误：匿名访问失败',
        //     })
        //   })
      }
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
