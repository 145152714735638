import request from '@/utils/request'

export function anonymousRegister(data = {}) {
  return request({
    url: '/users/anonymousRegister',
    method: 'post',
    data,
  })
}

export function getUsers(query) {
  return request({
    url: '/users',
    method: 'get',
    params: query,
  })
}

export function getCurrentUser() {
  return request({
    url: '/users/info',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/users/logout',
    method: 'post',
  })
}

export function register(data = {}) {
  return request({
    url: '/users/register',
    method: 'post',
    data,
  })
}

export function login(data = {}) {
  return request({
    url: '/users/login',
    method: 'post',
    data,
  })
}