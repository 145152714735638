import Cookies from 'js-cookie'
const TokenKey = process.env.VUE_APP_AUTH_TKEN || 'diy_vr_frontend_token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(tokenRaw, expires = 7) {
  return Cookies.set(TokenKey, parseTokenRaw(tokenRaw), { expires: expires })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function parseTokenRaw(tokenRaw) {
  if (!tokenRaw) {
    return ''
  }

  if (typeof tokenRaw === 'string') {
    return tokenRaw
  }

  const { token, token_type } = tokenRaw
  if (!token_type) {
    return token
  }

  return token_type + ' ' + token
}
