import request from '@/utils/request'

export function getProducts(query) {
  return request({
    url: '/product/index',
    method: 'get',
    params: query,
  })
}

export function createProduct(data) {
  return request({
    url: '/product/create',
    method: 'post',
    data,
  })
}

export function viewProduct(id) {
  const query = {id:id}
  return request({
    url: `/product/view`,
    method: 'get',
    params:query
  })
}
export function updateProduct(id, data) {
  const query = {id:id}
  return request({
    url: `/product/update`,
    method: 'post',
    params:query,
    data,
  })
}

export function appendProductScene(id) {
  return process.env.VUE_APP_BASE_API + `/product/appendScene?id=${id}`
}

export function removeScene(productId, sceneId) {
  const query = {productId: productId, sceneId:sceneId}
  return request({
    url: `/product/removeScene`,
    method: 'post',
    params:query
  })
}
export function updateScene(productId, sceneId, data) {
  const query = {productId: productId, sceneId:sceneId}
  return request({
    url: `/product/updateScene`,
    method: 'post',
    params:query,
    data,
  })
}

export function makeHotpot(data) {
  return request({
    url: `/product/makeHotpot`,
    method: 'post',
    data,
  })
}

export function viewHotpot(id) {
  const query = {id: id}
  return request({
    url: `/product/hotpot`,
    method: 'get',
    params:query
  })
}

export function previewProduct(id, query = {}) {
  query['id'] = id
  return request({
    url: `/product/preview`,
    method: 'get',
    params: query,
  })
}

export function getHotpots(query) {
  return request({
    url: '/product/hotpots',
    method: 'get',
    params: query,
  })
}

export function clearHotpots(sceneId) {
  const data = { scene_id: sceneId }
  return request({
    url: `/product/hotpotClear`,
    method: 'post',
    data,
  })
}

export function removeHotpot(id) {
  const data = { id: id }
  return request({
    url: `/product/hotpotRemove`,
    method: 'post',
    data,
  })
}

export function showHotpot(uuid) {
  const query = {uuid:uuid}
  return request({
    url: `/product/hotpotShow`,
    method: 'get',
    params:query
  })
}

export function changeProductStatus(id, status) {
  const data = { status: status }
  const query = {id: id}
  return request({
    url: `/product/changeStatus`,
    method: 'post',
    params:query,
    data,
  })
}

export function removeProduct(id) {
  const data = { id: id }
  return request({
    url: `/product/delete`,
    method: 'post',
    data,
  })
}

export function likeProduct(id) {
  const data = { id: id }
  return request({
    url: `/product/like`,
    method: 'post',
    data,
  })
}

/**
 * 排序场景
 * @param {*} productId 
 * @param {*} to 
 * @param {*} from 
 * @returns 
 */
export function sortScene(productId, to, from) {
  const query = {productId: productId}
  const data = {
    to: to,
    from: from
  }
  return request({
    url: `/product/sortScene`,
    method: 'post',
    params:query,
    data,
  })
}
