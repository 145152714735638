import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { getToken } from '@/utils/auth'
import { openLoginRegDialog } from '@/components/dialog/openLoginRegHandler'

function responseHandle(code) {
  if ([401001, 401003].includes(code)) {
    store.dispatch('user/resetToken').then(() => {
      if (code === 401001) {
        openLoginRegDialog(store.getters.systemTitle, 'login', {
          onSubmit() {
            console.log('点了确定按钮')
          },
          onCancel() {
            console.log('点了取消按钮')
          },
        })
      }
    })
  } else if (code === 4031101) {
    router.replace({
      path: '/403',
    })
  } else if (code === 4041001) {
    router.replace({
      path: '/404',
    })
  }
}
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    if (store.getters.token) {
      config.headers['X-Auth-Token'] = getToken()
    }
    return config
  },
  (error) => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data
    if (res.code !== 0) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000,
      })
      responseHandle(res.code)
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    // restfull
    const errorResponse = error.response
    if (errorResponse && 'data' in errorResponse && errorResponse.data) {
      const { code, message } = errorResponse.data
      Message({
        message: message,
        type: 'error',
        duration: 5 * 1000,
      })
      return responseHandle(code)
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000,
      })
    }

    return Promise.reject(error)
  }
)

export default service
