import Cookies from 'js-cookie'
import settings from '@/settings'
const state = {
  name: settings.title,
  device: settings.device,
  size: Cookies.get('size') || 'medium',
  openLoginModal: false, // 未登录时，弹出登录框
  allowAnonymousVisit: true, // 是否允许匿名访问，匿名用户会自动注册并返回一个系统账户
}

const mutations = {
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_SIZE: (state, size) => {
    state.size = size
    Cookies.set('size', size)
  },
  TOGGELE_OPEN_LOGIN_MODAL: (state, status) => {
    state.openLoginModal = status
  },
}

const actions = {
  toggleOpenLoginModal({ commit }, status) {
    commit('TOGGELE_OPEN_LOGIN_MODAL', status)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
