export default {
  // 以下配置后期可以做成页面配置项
  orientation: 'front',
  anchor: 'center center',
  tooltipPosition: 'top center',
  miniSize: [32, 32], // 小图标
  mediumSize: [64, 64], // 中图标
  bigSize: [128, 128], // 大图标
  polygonRadStrokeWidth: 1, // 多边形
  polygonRadSvgStyleFill: '#E84B3C', // 多边形
  polygonRadStroke: '#C0382B', // 多边形
  polylineRadStroke: '#27AF60', // 线条
  polylineRadStrokeWidth: 1, // 线条
  typeOptions: [
    {
      value: 'sceneChange',
      label: '全景切换',
    },
    {
      value: 'hyperlink',
      label: '超链接',
    },
    {
      value: 'image',
      label: '图片热点',
    },
    {
      value: 'video',
      label: '视频热点',
    },
    {
      value: 'text',
      label: '文本热点',
    },
    {
      value: 'iot',
      label: '物联网热点',
    },
  ],
}
