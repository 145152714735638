import moment from 'moment'
import defaultHotpotConfig from '@/config/vr-hotpot'

/**
 * VR热点ICON过滤
 * @param {*} hotpot
 * @returns
 */
export function vrHotpotIconFilter(hotpot) {
  if (hotpot.iconType === 'vr') {
    const src = require(`@/assets/default/icons/${hotpot.icon}.png`)
    return `<img src="${src}" class="hotpot-img"/>`
  } else if (hotpot.iconType === 'element') {
    return `<i class="${hotpot.icon}">`
  } else if (hotpot.iconType === 'polygon') {
    return `<i class="el-icon-menu"></i>`
  } else if (hotpot.iconType === 'polyline') {
    return `<i class="el-icon-minus"></i>`
  } else {
    // custom
    return `<img src="${hotpot.icon}" class="hotpot-img"/>`
  }
}

/**
 * VR热点类型过滤
 * @param {*} type
 * @returns
 */
export function vrHotpotTypeFilter(type) {
  const obj = defaultHotpotConfig.typeOptions.find(
    (item) => item.value === type
  )
  if (!obj) {
    return ''
  }

  return obj.label
}

/**
 * time format
 * @param {*} time object
 * @param {*} format string
 * @returns
 */
export function timeFormatFilter(time, format) {
  return moment(time).format(format)
}
