/* eslint-disable no-unused-vars */
import { logout } from '@/api/user'
import { getToken, setToken, removeToken, parseTokenRaw } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    intro: '',
    uuid: '',
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = parseTokenRaw(token)
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_INTRO: (state, intro) => {
    state.intro = intro
  },
  SET_UUID: (state, uuid) => {
    state.uuid = uuid
  },
}

const actions = {
  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      removeToken() // must remove  token  first
      resetRouter()
      commit('RESET_STATE')
      resolve()
      // logout()
      //   .then(() => {
      //     removeToken() // must remove  token  first
      //     resetRouter()
      //     commit('RESET_STATE')
      //     resolve()
      //   })
      //   .catch((error) => {
      //     reject(error)
      //   })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  },
  login({ commit }, user) {
    return new Promise((resolve) => {
      removeToken()
      commit('SET_NAME', user.name)
      commit('SET_AVATAR', user.avatar)
      commit('SET_INTRO', user.intro)
      commit('SET_TOKEN', user.token)
      commit('SET_UUID', user.uuid)
      setToken(user.token)
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
