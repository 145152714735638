<!-- eslint-disable no-unused-vars -->
<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="300px"
      :center="true"
      :append-to-body="true"
      class="login-reg-dialog"
    >
      <el-form :model="model" status-icon :rules="rules" :ref="formName" class="handle-dialog-form">
        <el-form-item prop="name">
          <el-input
            type="text"
            v-model="model.name"
            autocomplete="off"
            placeholder="请输入用户名"
            size="medium"
          >
            <i slot="prefix" class="el-input__icon el-icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="email" v-if="type === 'register'">
          <el-input
            type="text"
            v-model="model.email"
            autocomplete="off"
            placeholder="请输入邮箱"
            size="medium"
          >
            <i slot="prefix" class="el-input__icon el-icon-eleme"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="model.password"
            autocomplete="off"
            :placeholder="
              type === 'login'
                ? '请输入密码'
                : '6~10位：字母,数字,特殊字符至少2种'
            "
            size="medium"
          >
            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="check_pass" v-if="type === 'register'">
          <el-input
            type="password"
            v-model="model.check_pass"
            autocomplete="off"
            placeholder="请再次输入密码"
            size="medium"
          >
            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="company" v-if="type === 'register'">
          <el-input
            type="text"
            v-model="model.company"
            autocomplete="off"
            placeholder="请输入公司名称"
            size="medium"
          >
            <i slot="prefix" class="el-input__icon el-icon-s-cooperation"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="reg_code" v-if="type === 'register'">
          <el-input
            type="text"
            v-model="model.reg_code"
            autocomplete="off"
            placeholder="请输入6位邀请码"
            size="medium"
          >
            <i slot="prefix" class="el-input__icon el-icon-s-claim"></i>
          </el-input>
        </el-form-item>
        <el-form-item class="handle-form-btn-item clearfix">
          <div class="pull-left">
            <el-link
              type="primary"
              :underline="false"
              href="https://www.boyuntong.com"
              target="_blank"
            >《用户协议》</el-link>
          </div>
          <div class="pull-right">
            <el-button
              type="text"
              size="small"
              v-if="type === 'login'"
              @click="showRegisterForm"
            >立即注册</el-button>
            <el-button
              type="text"
              size="small"
              v-if="type === 'register'"
              @click="showLoginForm"
            >直接登录</el-button>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="onCancel">取 消</el-button> -->
        <el-button @click="onCancel" size="small" v-if="type === 'login'">匿名登录</el-button>
        <el-button
          type="primary"
          @click="login"
          size="small"
          v-if="type === 'login'"
          :loading="btnLoading"
        >登 录</el-button>
        <el-button type="primary" @click="register" size="small" v-if="type === 'register'">注 册</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { anonymousRegister, register, login } from '@/api/user'
import store from '@/store'
const defaultModel = {
  name: '',
  password: '',
  check_pass: undefined,
  company: undefined,
  reg_code: undefined,
  email: undefined
}

export default {
  name: 'LoginRegDialog',
  props: {},
  data() {
    return {
      isOpenFlag: false,
      dialogVisible: false,
      title: '',
      type: 'login',
      model: Object.assign({}, defaultModel),
      btnLoading: false
    }
  },
  computed: {
    formName() {
      return `${this.type}HandleForm`
    },
    rules() {
      const validatePass = (_rule, value, callback) => {
        // 密码强度:6~10位的数字+字母；字母+特殊字符，特殊字符+数字
        const reg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,10}$/
        if (value === '') {
          callback(new Error('请输入密码'))
        } else if (!reg.test(value)) {
          callback(
            new Error(
              '密码要求：6~10位的数字+字母；字母+特殊字符，特殊字符+数字'
            )
          )
        } else {
          if (this.model.check_pass !== '') {
            this.$refs[`${this.formName}`].validateField('check_pass')
          }
          callback()
        }
      }
      const validatePass2 = (_rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.model.password) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      const rules = {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 3,
            max: 20,
            message: '长度在 3 到 20 个字符',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 10,
            message: '长度在 6 到 10 个字符',
            trigger: 'blur'
          }
        ]
      }

      if (this.type === 'register') {
        rules['password'] = [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 10,
            message: '密码长度在 6 到 10 个字符',
            trigger: 'blur'
          },
          { validator: validatePass, trigger: 'blur' }
        ]
        rules['check_pass'] = [{ validator: validatePass2, trigger: 'blur' }]
        rules['reg_code'] = [
          { required: true, message: '请输入邀请码', trigger: 'blur' },
          {
            len: 6,
            message: '6位邀请码',
            trigger: 'blur'
          }
        ]
        rules['email'] = [
          {
            required: true,
            type: 'email',
            trigger: 'blur',
            message: '请输入正确的邮箱号码'
          }
        ]
      }

      return rules
    }
  },
  methods: {
    showLoginForm() {
      this.type = 'login'
      this.reset()
    },
    showRegisterForm() {
      this.type = 'register'
      this.reset()
    },
    open(title, type, cbs = {}) {
      this.title = title
      this.type = type
      this.dialogVisible = true
      this.cbs = cbs
      this.isOpenFlag = true
    },
    login() {
      // 处理登录
      this.btnLoading = true
      this.$refs[`${this.formName}`].validate((valid, errs) => {
        if (valid) {
          login(this.model)
            .then(async result => {
              const { code, message, data } = result
              setTimeout(() => {
                this.btnLoading = false
              }, 1000)
              if (code !== 0) {
                this.$message.error(message)
                return false
              }
              await store.dispatch('user/login', data)
              setTimeout(() => {
                location.reload()
              }, 500)
            })
            .catch(_err => {
              setTimeout(() => {
                this.btnLoading = false
              }, 1000)
              this.$message.error(_err.message)
            })
        } else {
          setTimeout(() => {
            this.btnLoading = false
          }, 1500)
          for (const key in errs) {
            this.$message.error(errs[key][0].message)
            return false
          }
        }
      })
      this.afterHock()
      this.reset()
    },
    afterHock(method = 'onSubmit') {
      if (
        Object.prototype.toString.call(this.cbs[method]) === '[object Function]'
      ) {
        this.cbs[method]()
      }
      // if (
      //   Object.prototype.toString.call(this.cbs['onCannel']) ===
      //   '[object Function]'
      // ) {
      //   this.cbs['onCannel']()
      // }
    },
    async register() {
      this.btnLoading = true
      this.$refs[`${this.formName}`].validate(async valid => {
        if (valid) {
          const { code, message, data } = await register(this.model)
          this.btnLoading = false
          if (code !== 0) {
            this.$message.error(message)
            return false
          }

          await store.dispatch('user/login', data)
          setTimeout(() => {
            location.reload()
          }, 500)
        } else {
          this.btnLoading = false
          return false
        }
      })
    },
    onCancel() {
      this.dialogVisible = false
      this.isOpenFlag = false
      this.reset()
      anonymousRegister()
        .then(async response => {
          const { code, message, data } = response
          if (code !== 0) {
            this.$message.error(message)
            return false
          }

          await store.dispatch('user/login', data)
          setTimeout(() => {
            location.reload()
          }, 500)
          // if (
          //   Object.prototype.toString.call(this.cbs['onCancel']) ===
          //   '[object Function]'
          // ) {
          //   this.cbs['onCancel']()
          // }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(_error => {
          this.$message.error('服务器内部错误：匿名访问失败')
        })
    },
    reset() {
      this.$nextTick(() => {
        this.$refs[`${this.formName}`].resetFields()
        this.$refs[`${this.formName}`].clearValidate()
      })
      this.model = Object.assign({}, defaultModel)
    }
  }
}
</script>
<style lang="less" scoped>
.handle-dialog-form {
  .handle-form-btn-item {
    margin: 0;
    a {
      font-size: 12px;
      padding-left: 5px;
    }
  }
}
</style>
<style lang="less">
.login-reg-dialog {
  .el-dialog--center {
    .el-dialog__body {
      padding: 10px 10px 30px !important;
    }
    .el-dialog__footer {
      padding-top: 0px;
    }
  }
}
</style>
