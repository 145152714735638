import Dialog from './LoginRegDialog'
import Vue from 'vue'
let $vm, dialogConstructor

export function openLoginRegDialog(title, type, cbs) {
  if (!dialogConstructor) {
    dialogConstructor = Vue.extend(Dialog)
  }
  if (!$vm) {
    $vm = new dialogConstructor()
  }
  $vm.$mount().open(title, type, cbs)
}
