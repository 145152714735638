<template>
  <el-container v-loading="loading" class="center-product">
    <el-header class="home-header">
      <vr-header>
        <template v-slot:center>
          <div class="home-header-title">{{ systemTitle }}</div>
        </template>
      </vr-header>
    </el-header>
    <el-container class="home-body">
      <div class="author">
        <div class="author-left-nav">
          <div class="author-left-nav-avatar" v-if="currentUser">
            <img :src="currentUser.avatar" alt />
          </div>
          <div class="author-left-nav-info">
            <div class="author-left-nav-info-nickname" v-if="currentUser">
              <h1>
                <span>{{ currentUser.name }}</span>
              </h1>
            </div>
            <div class="author-left-nav-info-passwd" v-if="currentUser">
              <el-tooltip
                v-if="currentUser.anonymous"
                class="item"
                effect="dark"
                content="匿名用户请记住账号信息，便于下次使用"
                placement="top-start"
              >
                <span>匿名用户默认密码：Aa123456</span>
              </el-tooltip>
              <span v-else>{{ currentUser.company }}</span>
            </div>
          </div>
        </div>
        <div class="author-right-nav">
          <el-select
            v-model="query.status"
            clearable
            placeholder="状态筛选"
            class="status-select"
            @change="_getProducts(true)"
            @clear="_getProducts(true)"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
          <el-input
            placeholder="输入关键字进行搜索（标题、地址）"
            v-model="query.keyword"
            class="input-with-select"
            clearable
            @clear="_getProducts(true)"
          >
            <el-button slot="append" icon="el-icon-search" @click="_getProducts(true)"></el-button>
          </el-input>
          <el-button
            type="primary"
            @click="startDIY"
            :disabled="currentUser && currentUser.anonymous"
          >开始创作</el-button>

          <el-button type="default" @click="logout">退出登录</el-button>
        </div>
      </div>
      <div class="products">
        <div class="products-body" v-if="products.length">
          <div class="products-content">
            <div class="products-item" v-for="item in products" :key="item.id">
              <el-skeleton :loading="skeletonLoading" :throttle="500" animated>
                <template slot="template">
                  <el-skeleton-item variant="image" style="height: 280px;" />
                  <div style="padding: 14px;">
                    <el-skeleton-item variant="p" style="width: 100%" />
                    <div style="display: flex; align-items: center; justify-items: space-between;">
                      <el-skeleton-item variant="text" style="width: 40%" />
                      <!-- <el-skeleton-item variant="text" style="width: 30%;" /> -->
                    </div>
                  </div>
                </template>
                <template>
                  <div class="products-item-status">
                    <span :class="item.status">{{item.statusText}}</span>
                  </div>
                  <div class="products-item-img" @click="jumpShow(item)">
                    <el-image :src="item.coverFull" fit="cover" />
                  </div>
                  <div class="products-item-info">
                    <!-- @click="jumpDIY(item.id)" -->
                    <div class="products-item-info-title">{{ item.title }}</div>
                    <div class="products-item-info-desc">
                      <span>
                        <i class="el-icon-view"></i>
                        {{ item.clickCount }}
                      </span>
                      <span>
                        <i class="el-icon-thumb"></i>
                        {{ item.likeCount }}
                      </span>
                      <div class="products-item-info-btn">
                        <el-dropdown
                          @command="vrCtrl(item.id, $event)"
                          v-if="!currentUser.anonymous"
                        >
                          <span class="el-dropdown-link">
                            <i class="el-icon-more"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="edit" v-if="item.status !== 'published'">编辑</el-dropdown-item>
                            <el-dropdown-item command="close" v-if="item.status !== 'closed'">关闭</el-dropdown-item>
                            <el-dropdown-item
                              command="publish"
                              v-if="item.status !== 'published'"
                            >发布</el-dropdown-item>
                            <el-dropdown-item command="delete">删除</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </div>
                  </div>
                </template>
              </el-skeleton>
            </div>
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="query.page_size"
            :current-page="query.page_current"
            :page-count="pageCount"
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          ></el-pagination>
        </div>

        <el-empty description="暂无作品" v-else></el-empty>
      </div>
    </el-container>
    <scene-img-form-dialog ref="sceneImgDialog" uploadClass="upload-scene-img" />
  </el-container>
</template>

<script>
// const _ = require('lodash')
import { getCurrentUser } from '@/api/user'
import { getProducts, changeProductStatus, removeProduct } from '@/api/vr'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
const defaultQuery = {
  keyword: '',
  page_current: 1,
  page_size: 10
}
export default {
  name: 'HomeIndex',
  components: {
    VrHeader: () => import('./layout/header.vue'),
    SceneImgFormDialog: () => import('./form/SceneImgFormDialog.vue')
  },
  data() {
    return {
      query: Object.assign({}, defaultQuery),
      currentUser: null,
      loading: false,
      skeletonLoading: true,
      total: 0,
      pageCount: 0,
      products: [],
      showSceneIgDialog: false,
      statusOptions: [
        { key: 'published', value: '已发布' },
        { key: 'closed', value: '已关闭' },
        { key: 'drafted', value: '草稿' }
      ]
    }
  },
  computed: {
    ...mapGetters(['systemTitle'])
  },
  created() {
    this.getCurrentUser()
    this._getProducts = debounce((reset = false) => {
      this.getProducts(reset)
    }, 200)
  },
  mounted() {
    this.getProducts()
  },
  beforeDestroy() {},
  methods: {
    // 获取当前登录用户信息
    async getCurrentUser() {
      this.loading = true
      const { data: user } = await getCurrentUser()
      this.currentUser = user
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    // 打开上传场景登录框
    startDIY() {
      // this.$router.push('/diy')
      this.$refs.sceneImgDialog.open()
    },
    // 跳转预览页
    jumpShow(product) {
      if (product.status !== 'published') {
        return false
      }
      let routeUrl = this.$router.resolve({
        path: `/view/${product.id}`
      })
      window.open(routeUrl.href, '_blank')
    },
    // 跳转装修页
    jumpDIY(id) {
      this.$router.push(`/diy/${id}`)
    },
    // 退出登录
    logout() {
      // 首先，需要做完用户登录，才能退出，退出后会在服务端删除用户token
      // this.$message.info('暂未开放')
      this.$confirm('确定要退出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store.dispatch('user/logout').then(() => {
            location.reload()
          })
        })
        .catch(() => {})
    },
    // 获取作品明细
    async getProducts(reset = false) {
      this.skeletonLoading = true
      getProducts(this.query).then(result => {
        const {
          page_current,
          page_size,
          total,
          page_count,
          list: products
        } = result.data
        this.query.page_current = page_current
        this.query.page_size = page_size
        this.total = total
        this.pageCount = page_count
        this.products = products
        if (reset) {
          this.resetFields()
        }

        setTimeout(() => (this.skeletonLoading = false), 500)
      })
    },
    // 分页大小变化
    onSizeChange(pageSize) {
      this.$set(this.query, 'page_size', pageSize)
      this._getProducts()
    },
    // 分页当前页变化
    onCurrentChange(currentPage) {
      this.$set(this.query, 'page_current', currentPage)
      this._getProducts()
    },
    // 分页点击上一页
    onPrevClick(currentPage) {
      this.$set(this.query, 'page_current', currentPage)
      this._getProducts()
    },
    // 分页点击下一页
    onNextClick(currentPage) {
      this.$set(this.query, 'page_current', currentPage)
      this._getProducts()
    },
    // 重置查询字段
    resetFields() {
      this.$set(this.query, 'page_current', defaultQuery.page_current)
      this.$set(this.query, 'page_size', defaultQuery.page_size)
    },
    // 变更作品状态
    changeProductStatus(id, status) {
      const message =
        status === 'closed' ? '确定要关闭此作品？' : '确定要发布此作品？'
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          changeProductStatus(id, status).then(result => {
            if (result.code === 0) {
              this.getProducts(true)
            }
          })
        })
        .catch(() => {})
    },
    // 删除作品
    deleteProduct(id) {
      this.$confirm('删除不可恢复，确定要删除此作品？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          removeProduct(id).then(result => {
            if (result.code === 0) {
              this.getProducts(true)
            }
          })
        })
        .catch(() => {})
    },
    // 作品按钮组
    vrCtrl(id, command) {
      switch (command) {
        case 'edit':
          return this.jumpDIY(id)
        case 'close':
          return this.changeProductStatus(id, 'closed')
        case 'publish':
          return this.changeProductStatus(id, 'published')
        case 'delete':
          return this.deleteProduct(id)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.center-product {
  .home-body {
    display: block;
    background-color: #f8f8f8;
    .author {
      width: 90%;
      margin: 0 auto;
      height: 100px;
      &::after {
        display: block;
        content: '';
        clear: both;
      }
      .author-left-nav {
        float: left;
        padding-top: 20px;
        display: flex;
        flex: 1;
        .author-left-nav-avatar {
          img {
            display: block;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            vertical-align: middle;
            background-color: rgb(244, 244, 244);
          }
        }
        .author-left-nav-info {
          padding: 10px 0 0 20px;
          .author-left-nav-info-nickname {
            h1 {
              font-size: 16px;
              color: #000;
              padding: 0px;
              margin: 0px;
            }
            margin-bottom: 10px;
          }
          .author-left-nav-info-passwd {
            cursor: pointer;
            span {
              font-size: 12px;
              color: #aba8a8;
            }
          }
        }
      }
      .author-right-nav {
        float: right;
        padding-top: 20px;
        .status-select {
          width: 120px;
        }
        .input-with-select {
          width: 400px;
          margin-right: 15px;
        }
      }
    }
    .products {
      width: 90%;
      margin: 0 auto;
      min-height: 600px;
      .products-body {
        .products-content {
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          padding-top: 40px;
          .products-item {
            width: 280px;
            margin-right: 20px;
            margin-bottom: 40px;
            padding-bottom: 56px;
            position: relative;
            overflow: hidden;
            .products-item-status {
              width: 100px;
              height: 100px;
              position: absolute;
              z-index: 2;
              top: -50px;
              right: -50px;
              transform: rotate(45deg);
              span {
                position: absolute;
                bottom: 0;
                display: block;
                font-size: 14px;
                color: #fff;
                background: #e6a23c;
                width: 100px;
                text-align: center;
                &.published {
                  background: #67c23a;
                }
                &.closed {
                  background: #f56c6c;
                }
              }
            }
            .products-item-img {
              cursor: pointer;
              width: 100%;
              overflow: hidden;
              position: relative;
              text-align: center;
            }
            .products-item-info {
              height: 56px;
              overflow: hidden;
              position: absolute;
              left: 0;
              bottom: 0;
              -webkit-transition: height 200ms;
              -o-transition: height 200ms;
              transition: height 200ms;
              width: 100%;
              background-color: #fff;
              z-index: 3;
              padding-bottom: 5px;
              .products-item-info-title {
                padding-left: 10px;
                cursor: pointer;
                &:hover {
                  color: #409eff;
                }
                font-size: 14px;
                color: #000;
                font-weight: bold;
                padding-top: 15px;
                padding-bottom: 10px;
                width: 100%;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .products-item-info-desc {
                padding-left: 10px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                color: #7a7a7a;
                font-size: 12px;
                height: 12px;
                justify-content: space-between;
                span {
                  white-space: nowrap;
                  margin-right: 20px;
                }
                .products-item-info-btn {
                  font-size: 14px;
                  color: #000;
                }
              }
            }
          }
        }
      }
      .el-empty {
        margin-top: 80px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .home-body {
      overflow: auto;
      .author {
        height: auto;
        .author-left-nav {
          float: none;
        }
        .author-right-nav {
          float: none;
          .status-select {
            width: 100%;
            margin-bottom:5px;
          }
          .input-with-select {
            width: 100%;
            margin-right: 15px;
            margin-bottom:5px;
          }
        }
      }
    }
    .products {
      padding-top: 10px;
    }
  }
}
</style>
